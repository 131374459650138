import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiModelApi, getCarApi, getCarYearApi } from "../../../api";
import { globalHandleChange } from "../../../Utils/HelperFunction";
import { CarLoader } from "../../../Utils/Loader";
import Select from "react-select";
import Navbar from "./Navbar";
import SelectStyle from "../../../Utils/CustomSelectStyle";
import { toast } from "react-hot-toast";
import { GigContext, RequestContext } from "../../../app_context/UserContext";
import Header from "../../../Components/Header/Header";

const VehicleInfromation = ({ transportData, history, vehicleValue, dummy, setDummy, dummyTwo, setDummyTwo, dummyHistory, otherModel, setOtherModel }) => {
	const [carMake, setCarMake] = useState({ data1: null, loading: true });
	const [carModel, setCarModel] = useState({ data: null, loading: false });
	const [carYear, setCarYear] = useState("");
	
	const [formErrors, setFormErrors] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);

	const [initialValues, setInitialValues] = useState({
		vehiclePlateNumber: "",
		vehicleMake: "",
		vehicleModel: "",
		vehicleColor: "",
	});

	const [formValues, setFormValues] = useState(initialValues);

	const { setRequestStep } = useContext(RequestContext);
	const { vehicleInfoFormik } = useContext(GigContext);

	// const handleChange = (e) => {
	//   vehicleInfoFormik.setFieldValue("transmission",e.target.name, e.target.value);
	// }

	const fetchMake = async () => {
		const makeLoader = toast.loading("Fetching vehicle makes...");
		// setCarMake({loading : true})
		try {
			const res1 = await getCarApi();
			if (res1.status === 200) {
				toast.dismiss(makeLoader);
				// setCarMake({loading : false})
				setCarMake((v) => ({ ...v, data1: res1?.data, loading: false }));
				// const datas =  res1?.data?.unshift("Others");
			} else {
				toast.dismiss(makeLoader);
				setCarMake((v) => ({ ...v, data1: null, loading: false }));
			}
		} catch (error) {
			toast.dismiss(makeLoader);
			// setCarMake({loading : true})
			return error;
		}
	};

	const handleMakeChange = async (e) => {
		// const modelLoader = toast.loading("Fetching vehicle models...");
		// setFormValues({ ...formValues, vehicleMake: e.value });
		// vehicleInfoFormik.setFieldValue("vehicleMake", e?.value);
		// setFormErrors({ ...formErrors, vehicleMake: "" })
		if (e.value === "Others") {
			setDummy("Others");
			setDummyTwo({ label: "Others", value: "Others" });
			vehicleInfoFormik.setFieldValue("vehicleMake", "");
			vehicleInfoFormik.setFieldValue("vehicleModel", "");
		} else if (e.value !== "Others") {
			setOtherModel("")
			const modelLoader = toast.loading("Fetching vehicle models...");
			setFormValues({ ...formValues, vehicleMake: e.value });
			vehicleInfoFormik.setFieldValue("vehicleMake", e?.value);
			setFormErrors({ ...formErrors, vehicleMake: "" });
			setDummy("");
			setDummyTwo({ value: e.value, label: e.label });
			try {
				const modelResponse = await getApiModelApi({ make: e?.value });
				if (modelResponse.status === 200) {
					toast.dismiss(modelLoader);
					const unique = modelResponse.data.filter((obj, index) => {
						return index === modelResponse.data?.findIndex((o) => obj.model === o.model);
					});
					setCarModel({ data: unique, loading: false });
				}
			} catch (error) {
				toast.dismiss(modelLoader);
				// setCarModel({ loading: false });
			}
		}
		return;
	};

	const handleModelChange = (e) => {
		if (e.value === "Others") {
			setOtherModel(e.value)
			vehicleInfoFormik.setFieldValue("vehicleModel", "");
		} else {
			vehicleInfoFormik.setFieldValue("vehicleModel", e?.value);
			setFormValues({ ...formValues, vehicleModel: e.value });
			setFormErrors({ ...formErrors, vehicleModel: "" });
		}
	};
	const handleStepCheck = () => {
		const z = vehicleInfoFormik.errors;
		const x = Boolean(z.keys(vehicleInfoFormik.errors).length === 0);
		// if(!vehicleInfoFormik?.values){
		// setRequestStep(5)
		// }
	};

	const makesSorted = carMake?.data1?.map((el, i) => {
		// return el = el.charAt(0).toUpperCase() + el.slice(1)
		return (el = el.charAt(0).toUpperCase() + el.slice(1));
	});

	const carMakeOptions = makesSorted?.sort()?.map((el, i) => {
		const container = {};
		container["value"] = el;
		container["label"] = el;
		return container;
	});
	carMakeOptions?.push({ value: "Others", label: "Others" });

	const modelSorted = carModel?.data?.map((el, i) => {
		return (el = el.model.charAt(0).toUpperCase() + el.model.slice(1));
	});
	const carModelOptions = modelSorted?.sort()?.map((el, i) => {
		const container = {};
		container["value"] = el;
		container["label"] = el;

		return container;
	});
	carModelOptions?.push({ value: "Others", label: "Others" });
	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormValues({ ...formValues, [name]: value });
		setFormErrors({ ...formErrors, [name]: "" });
		vehicleInfoFormik?.setFieldValue(name, value);
	};

	const handleChange2 = (e) => {
		const { name, value } = e.target;
		if(value?.length<5){
		setFormValues({ ...formValues, [name]: value });
		setFormErrors({ ...formErrors, [name]: "" });
		vehicleInfoFormik?.setFieldValue(name, value);
		}
	};

	const handleSubmit = (e) => {
		const plateNumber = vehicleInfoFormik?.values?.vehiclePlateNumber && vehicleInfoFormik?.values?.vehiclePlateNumber;
		// const isValidNumber = plateNumber
		// 	?.slice(-4)
		// 	?.split("")
		// 	?.map((el) => !isNaN(el))
		// 	.every((el) => el === true);

		setFormErrors(validate(formValues));
				// setRequestStep(5);

		if (vehicleInfoFormik?.isValid === true || isSubmit === true) {
			// if (isValidNumber) {
				setRequestStep(5);
			// } else {
			// 	toast.error("wrong plate number");
			// }
		}
	};

	const validate = (values) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		if (!values.vehiclePlateNumber) {
			errors.vehiclePlateNumber = "This field is required!";
		} else {
			errors.vehiclePlateNumber = "";
		}
		if (!values.vehicleMake) {
			errors.vehicleMake = "This field is required!";
		} else {
			errors.vehicleMake = "";
		}
		if (!values.vehicleModel) {
			errors.vehicleModel = "This field is required!";
		} else {
			errors.vehicleModel = "";
		}
		if (!values.vehicleColor) {
			errors.vehicleColor = "This field is required!";
		} else {
			errors.vehicleColor = "";
		}

		if (!values.vinNumber) {
			errors.vinNumber = "This field is required!";
		} else if(values?.vinNumber?.length < 4) {
			errors.vinNumber = "Please provide valid no";
		}else {
			errors.vinNumber = "";
		}

		return errors;
	};

	useEffect(() => {
		if (Object.keys(formErrors).length === 0 && isSubmit) {
		}
	}, [formErrors]);

	useEffect(() => {
		fetchMake();
	}, []);

	useEffect(() => {
		if (history?.history?.vehicleInfoFormikValue || vehicleValue) {
			setFormValues({
				...formValues,
				vehiclePlateNumber:
					vehicleInfoFormik?.data?.vehiclePlateNumber || history?.history?.vehicleInfoFormikValue?.vehiclePlateNumber || vehicleValue?.vehiclePlateNumber || "",
				vehicleMake:
					vehicleInfoFormik?.data?.vehicleMake ||
					history?.history?.vehicleInfoFormikValue?.vehicleMake ||
					vehicleValue?.vehicleMake ||
					dummyHistory?.dummyTwo?.value ||
					"",
				vehicleModel: vehicleInfoFormik?.data?.vehicleModel || history?.history?.vehicleInfoFormikValue?.vehicleModel || vehicleValue?.vehicleModel || "",
				vehicleColor: vehicleInfoFormik?.data?.vehicleColor || history?.history?.vehicleInfoFormikValue?.vehicleColor || vehicleValue?.vehicleColor || "",
			});

			setInitialValues({
				...initialValues,
				vehiclePlateNumber:
					vehicleInfoFormik?.data?.vehiclePlateNumber || history?.history?.vehicleInfoFormikValue?.vehiclePlateNumber || vehicleValue?.vehiclePlateNumber || "",
				vehicleMake:
					vehicleInfoFormik?.data?.vehicleMake ||
					history?.history?.vehicleInfoFormikValue?.vehicleMake ||
					vehicleValue?.vehicleMake ||
					dummyHistory?.dummyTwo?.value ||
					"",
				vehicleModel: vehicleInfoFormik?.data?.vehicleModel || history?.history?.vehicleInfoFormikValue?.vehicleModel || vehicleValue?.vehicleModel || "",
				vehicleColor: vehicleInfoFormik?.data?.vehicleColor || history?.history?.vehicleInfoFormikValue?.vehicleColor || vehicleValue?.vehicleColor || "",
			});

			// setFormErrors({})

			vehicleInfoFormik.setFieldValue(
				"vehicleMake",
				history?.history?.vehicleInfoFormikValue?.vehicleMake || vehicleValue?.vehicleMake || dummyHistory?.dummyTwo?.value
			);
			vehicleInfoFormik.setFieldValue("vehicleModel", history?.history?.vehicleInfoFormikValue?.vehicleModel || vehicleValue?.vehicleModel);
			vehicleInfoFormik.setFieldValue("vehicleColor", history?.history?.vehicleInfoFormikValue?.vehicleColor || vehicleValue?.vehicleColor);
			vehicleInfoFormik.setFieldValue(
				"vehicleOtherInformation",
				history?.history?.vehicleInfoFormikValue?.vehicleOtherInformation || vehicleValue?.vehicleOtherInformation
			);
			vehicleInfoFormik.setFieldValue("vehiclePlateNumber", history?.history?.vehicleInfoFormikValue?.vehiclePlateNumber || vehicleValue?.vehiclePlateNumber);
			vehicleInfoFormik.setFieldValue("transmission", history?.history?.vehicleInfoFormikValue?.transmission || vehicleValue?.transmission);

			// setIsSubmit(true)
		}
	}, [history?.history?.vehicleInfoFormikValue, vehicleValue, dummyHistory]);

	useEffect(() => {
		if (dummyHistory) {
			setDummyTwo({
				value: dummyHistory?.dummyTwo?.value ? dummyHistory?.dummyTwo?.value : "",
				label: dummyHistory?.dummyTwo?.label ? dummyHistory?.dummyTwo?.label : "",
			});
		}
		if (dummyHistory?.dummy === "Others") {
			setDummy("Others");
		}
	}, []);
	return (
		<div>
			{/* <Navbar /> */}
			<Header/>
			<div className='main-content-container'>
				<div className='container mb-3 mt-3'>
					<div style={{marginTop: 80}}>
						<h4 className='fw-bold '>Vehicle information</h4>
					</div>
					<form>
						{console.log(vehicleInfoFormik,'vehicleInfoFormik')}
						<div className='mt-3 '>
							<div className='mb-3 mt-2'>
								<input
									type='text'
									className='form-control'
									// className={Boolean(vehicleInfoFormik.touched?.vehiclePlateNumber && vehicleInfoFormik.errors?.vehiclePlateNumber) ? "form-control border-danger " :"form-control "}
									id='exampleFormControlInput1'
									placeholder='License plate '
									name='vehiclePlateNumber'
									value={vehicleInfoFormik.values.vehiclePlateNumber}
									onChange={handleChange}
								/>
								{/* <p className='text-danger pt-2'>{formErrors.vehiclePlateNumber}</p> */}
							</div>
							<div className='mb-3 mt-2'>
								<input
									type='number'
									maxLength={4}
									// className='form-control'
									className={Boolean(formErrors?.vinNumber) ? "form-control border-danger " :"form-control "}
									id='exampleFormControlInput1'
									placeholder='Four last digits of the VIN '
									name='vinNumber'
									value={vehicleInfoFormik.values.vinNumber}
									onChange={handleChange2}
								/>
								{formErrors?.vinNumber&&<p className='text-danger pt-2'>{formErrors?.vinNumber}</p>}
							</div>
							<div className='mb-3'>
								<Select
									options={carMakeOptions}
									name='vehicleMake'
									placeholder='Vehicle make'
									// isClearable
									// isSearchable
									id='cars'
									styles={SelectStyle}								
									value={dummyTwo ? dummyTwo : ""}
									// value={
									//   Boolean(vehicleInfoFormik.values?.vehicleMake)
									//     ? {
									//       label: vehicleInfoFormik.values.vehicleMake,
									//       value: vehicleInfoFormik.values.vehicleMake,
									//     }
									//     : null
									// }
									// className="form-select"
									onChange={handleMakeChange}
									className={formErrors.vehicleMake ? "error" : ""}
									onFocus={() => {
										// vehicleInfoFormik.setFieldValue("vehicleMake", "");
										vehicleInfoFormik.setFieldValue("vehicleModel", "");
										setFormValues((v) => ({ ...v, vehicleMake: "", vehicleModel: "" }));
									}}
								/>

								<p className='text-danger pt-2'>{formErrors.vehicleMake}</p>
							</div>

							{dummy === "Others" ? (
								<div>
									<div className='mb-3 mt-2'>
										<input
											type='text'
											// className='form-control'
											className={Boolean(formErrors?.vehicleMake) ? "form-control border-danger " :"form-control "}
											id='exampleFormControlInput1'
											placeholder='Vehicle make'
											name='vehicleMake'
											value={vehicleInfoFormik.values.vehicleMake}
											onChange={handleChange}
										/>
										<p className='text-danger pt-2'>{formErrors.vehicleMake}</p>
									</div>

									<div className='mb-3 mt-2'>
										<input
											type='text'
											// className='form-control'
											className={Boolean(formErrors?.vehicleModel) ? "form-control border-danger " :"form-control "}
											placeholder='Vehicle model'
											name='vehicleModel'
											value={vehicleInfoFormik.values.vehicleModel}
											onChange={handleChange}
										/>
										<p className='text-danger pt-2'>{formErrors.vehicleModel}</p>
									</div>
								</div>
							) : (
								otherModel === "Others" ?
								<div className='mb-3 mt-2'>
										<input
											type='text'
											// className='form-control'
											className={Boolean(formErrors?.vehicleModel) ? "form-control border-danger " :"form-control "}
											placeholder='Vehicle model'
											name='vehicleModel'
											value={vehicleInfoFormik.values.vehicleModel}
											onChange={handleChange}
										/>
										<p className='text-danger pt-2'>{formErrors.vehicleModel}</p>
									</div> :
								<div className='mb-3'>
									<Select
										options={carModelOptions}
										name='vehicleModel'
										placeholder='Vehicle model'
										id='cars'
										styles={SelectStyle}
										value={
											Boolean(vehicleInfoFormik.values?.vehicleModel)
												? {
														label: vehicleInfoFormik.values.vehicleModel,
														value: vehicleInfoFormik.values.vehicleModel,
												  }
												: null
										}
										// className="form-select"
										className={Boolean(formErrors?.vehicleModel) ? "error" :""}
										onChange={handleModelChange}
										onFocus={() => {
											vehicleInfoFormik.setFieldValue("vehicleModel", "");
										}}
									/>
									<p className='text-danger pt-2'>{formErrors.vehicleModel}</p>
								</div>
							)}

							<div className='mb-3'>
								<select
									className='form-select'
									aria-label='Default select example'
									name='transmission'
									value={vehicleInfoFormik.values.transmission}
									onChange={(e) => {
										globalHandleChange(e, vehicleInfoFormik);
									}}>
									<option selected value='Automatic'>
										Automatic
									</option>
									<option value='Manual'>Manual</option>
								</select>
							</div>
							<div className='mb-3'>
								<input
									type='text'
									// className='form-control'
									className={Boolean(formErrors?.vehicleColor) ? "form-control border-danger " :"form-control "}
									id='exampleFormControlInput1'
									placeholder='Color'
									name='vehicleColor'
									value={vehicleInfoFormik.values.vehicleColor}
									onChange={handleChange}
								/>

								<p className='text-danger pt-2'>{formErrors.vehicleColor}</p>
							</div>
							<div className='mb-3'>
								<textarea
									className='form-control'
									placeholder='Other information'
									style={{ height: " 100px;" }}
									name='vehicleOtherInformation'
									value={vehicleInfoFormik.values.vehicleOtherInformation}
									onChange={handleChange}></textarea>
							</div>
						</div>
						<div className='fw-semibold p-14'>
							<p className='m-0 p-12 fw-semibold mb-2'>Insurance</p>

							<div className='row mb-2'>
								<div className='col-1 pe-0'>
									<i className='bi bi-caret-right-fill'></i>
								</div>
								<div className='col-11 ps-0'>The vehicle must be insured and covered for the transport. We highly recommend, that the contact </div>
							</div>
							<div className='row mb-2'>
								<div className='col-1 pe-0'>
									<i className='bi bi-caret-right-fill'></i>
								</div>
								<div className='col-11 ps-0'>
									{" "}
									Person hands the keys over to the driver. The condition of the vehicle is inspected with the driver.
									<button  onClick={() => setRequestStep(99)} className='text-decoration-none bg-white border-0'>
										<span className="fw-semibold" style={{ color: "blue" }}> more...</span>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div className='container mb-3 mt-5'>
					<button
						type='button'
						className='button btn-primary w-100 py-3 mb-3'
						onClick={() => {
							// setQuotation(7)
							// vehicleInfoFormik.handleSubmit()
							// handleStepCheck()
							// setRequestStep(5)
							handleSubmit();
						}}>
						Continue
					</button>
				</div>
				<div className="scrollHeight"></div>
			</div>
		</div>
	);
};

export default VehicleInfromation;
