import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import { getGigDetails } from '../../api'
import { UserContext } from '../../app_context/UserContext'
import NewMapTwo from '../../Components/NewMap/NewMapTwo'
import { formatPhoneNumber1, formatPhoneNumber3, formatPhoneNumber4 } from '../../Utils/HelperFunction'
import { Loader } from '../../Utils/Loader'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer/Footer'



const Edit = () => {
  const [gigDetails, setGigDetails] = useState({ data: "", loading: true });
  const [ratingState, setRatingState] = useState("0")

  const params = useParams();

  const app_context = useContext(UserContext);
  const { setClearReview } = app_context;
  // const { vehicleInfoFormik, dummy, dummyTwo, calculateCostFormik } = useContext(GigContext);

  const fetchGigDetailsById = async (id) => {
    setGigDetails({ loading: true });
    try {
      const res = await getGigDetails(id);
      if (res?.status === 200) {
        setGigDetails({ data: res?.data, loading: false });
        setRatingState(res?.data?.rating)
      }
    } catch (error) {
      setGigDetails({ loading: false });
    }
  };

  const handleCancel = () => {
    setClearReview(true);
    setTimeout(() => {
      navigate(`/cancel-policy/${params?.gigId}`)
    }, 10);
  };

  useEffect(() => {
    fetchGigDetailsById(params?.gigId);
  }, [params?.gigId]);

  const navigate = useNavigate();

  const navigateToMap = () => {
    navigate(`/track-your-order/${params?.gigId}`);
  };

  
  const isZeroMins = (time, type) => {
    const pickTime = moment(time).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly.split(":")?.slice(-1)[0]

    const dropTime = moment(gigDetails?.data?.dropOffTime).format("hh:mm A");

    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      }

    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      }
    }
  }

  const dropTime = moment(gigDetails?.data?.dropOffTime).format("hh:mm A")
  const dt1 = dropTime?.split(":")[0].replace(0, '') + ":" + dropTime?.split(":")[1].replace(":00", '')

  const isZeroMins1 = (time, type, timeToAdd) => {
    const s = Number(timeToAdd)

    const tTime = moment(time).add(s, "s").format()
    const pickTime = moment(tTime).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly.split(":")?.slice(-1)[0]

    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`;
      } else {
        return ` ${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]}`;
      }
    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`;
      } else {
        return `${timeOnly.split(")")?.slice(0)[0]}`;
      }
    }
  };


  return (
    <div className='footer-app-container'>
      {/* <Header /> */}
      <div className='dark-color header container d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/`);
            }}>
            <i className='bi bi-chevron-left h3 text-white ms-2'></i>
          </div>
          <div style={{ width: "60px" }} className=' text-align-center py-2 ms-3'>
            <img src='../component/images/Mask Group 2259.png' alt='#' style={{ width: "100%" }} />
          </div>
        </div>

        <div className=" d-flex justify-content-end align-items-center"></div>
      </div>
      <main className="mb-4 main-content-container">  
        {gigDetails && gigDetails?.loading ? (
          <div className="text-center mt-8 pt-4 ">
            <Loader />
          </div>
        ) : (
          <>
            <div className="container mb-3">
              <div className="mt-2 pt-2 ">
                <div
                  className="card  mb-3"
                  style={{ border: "1px solid #E9E9E9", marginTop: 80 }}
                >

                  <div className="border-top"></div>
                  <div className="card-body px-0 pb-0"> 
                    {gigDetails?.data?.status === "cancelled" ? (
                      ""
                    ) : (
                      <div className='light-yellow-bg py-2 px-2 mb-2 d-flex justify-content-center align-items-center'>
                        <p className='m-0 h2 fw-bold '>{gigDetails?.data?.orderId || "-"}</p>
                      </div>
                    )}  
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Customer Name</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.customerFullName || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Phone Number</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {formatPhoneNumber3(gigDetails?.data)}
                      </div> 
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Customer e-mail</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-14 fw-semibold ">{
                        gigDetails?.data?.customerEmail || "-"
                      }</div>
                    </div>

                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Customer other information</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-14 fw-semibold ">{
                        gigDetails?.data?.customerOtherInfos || "-"
                      }</div>
                    </div>

                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">
                        License plate
                      </div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.vehiclePlateNumber || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">
                      Four last digits of the VIN
                      </div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.vinNumber || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Make and Model</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.vehicleMake}{" "}
                        {gigDetails?.data?.vehicleModel || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Transmission</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.transmission || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Vehicle other information</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-14 fw-semibold ">{
                        gigDetails?.data?.vehicleOtherInformation || "-"
                      }</div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Pickup address</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.pickupAddress || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Dropoff address</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.dropOffAddress || "-"}
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">Distance</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.distance || "-"} miles
                      </div>
                    </div>
                    <div className="row mb-2 px-3">
                      <div className="col-5 text-fade p-11">
                        Estimated duration
                      </div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">
                        {gigDetails?.data?.estimateEndTime || "-"}
                      </div>
                    </div>

                    <div className='row mb-2 px-3'> 
                      <div className='col-5 text-fade p-11'>Earliest pickup time</div>
                      <div className='col-1 text-fade'>:</div> 
                      <div className='col-5 p-13 fw-semibold'>
                        {moment(gigDetails?.data?.pickUpDateAndTime).format("ddd. MMM. D (h:mm A)")}
                      </div>
                    </div>

                    <div className='row mb-2 px-3'>
                      <div className='col-5 text-fade p-11'>Must arrival latest</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-14 fw-semibold'>{isZeroMins(gigDetails?.data?.dropOffTime, "drop")}</div>
                    </div>

                    {gigDetails?.data?.mileageBeforePick && (
                      <div className='row mb-2 px-3'>
                        <div className='col-5 text-fade p-11'>Mileage before pickup</div>
                        <div className='col-1 text-fade'>:</div> 
                        <div className='col-5 p-13 fw-semibold'>{gigDetails?.data?.mileageBeforePick}</div>
                      </div>
                    )}
                    {gigDetails?.data?.mileageAfterPick && (
                      <div className='row mb-2 px-3'>
                        <div className='col-5 text-fade p-11'>Mileage after delivery</div>
                        <div className='col-1 text-fade'>:</div> 
                        <div className='col-5 p-13 fw-semibold'>{gigDetails?.data?.mileageAfterPick}</div>
                      </div>
                    )}
                    <div className='row mb-2 px-3'>
                      <div className='col-5 text-fade p-11'>Images before pickup</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-14 fw-semibold'>
                        <div className='d-flex flex-wrap justify-content-between'>
                          {gigDetails?.data?.driverDetails?.photosBeforePick?.length > 0 ?
                            gigDetails?.data?.driverDetails?.photosBeforePick?.map((e, index) => {
                              return <a href={e} target="_blank1" >
                                <div style={{ width: 80, height: 40 }} className='mb-2'>
                                  <img src={e} alt={`carImage${index}`} className='border w-100 h-100' />
                                </div>
                              </a>
                            }) : "Data Not Found"}
                        </div>

                      </div>
                    </div>
                    <div className='row mb-2 px-3'>
                      <div className='col-5 text-fade p-11'>Images after drop off</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-14 fw-semibold'>
                        <div className='d-flex flex-wrap justify-content-between'>

                          {gigDetails?.data?.driverDetails?.photosAfterDeliver?.length > 0 ?
                            gigDetails?.data?.driverDetails?.photosAfterDeliver?.map((e, index) => {
                              return <a href={e} target="_blank1" >
                                <div style={{ width: 80, height: 40 }} className='mb-2'>
                                  <img src={e} alt={`carImage${index}`} className='border w-100 h-100' />
                                </div>
                              </a>
                            }) : "Data Not Found"}
                        </div>

                      </div>
                    </div>
                    {gigDetails?.data?.driverId && (
                      <>
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Driver name</div>
                          <div className='col-1 text-fade'>:</div> 
                          <div className='col-5 p-13 fw-semibold'>{gigDetails?.data?.driverName || "-"}</div>
                        </div>
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Driver phone number</div>
                          <div className='col-1 text-fade'>:</div> 
                            <div className='col-5 p-13 fw-semibold'>{formatPhoneNumber4(gigDetails?.data?.driverPhone) || "-"}</div>
                        </div>
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Driver email</div>
                          <div className='col-1 text-fade'>:</div> 
                            <div className='col-5 p-13 fw-semibold'>{gigDetails?.data?.driverEmail || "-"}</div>
                        </div>
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Driver Start Time</div>
                          <div className='col-1 text-fade'>:</div> 
                            <div className='col-5 p-13 fw-semibold'>{gigDetails?.data?.driverDetails?.startTime || "-"}</div>
                        </div>
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Driver End Time</div>
                          <div className='col-1 text-fade'>:</div> 
                            <div className='col-5 p-13 fw-semibold'>{gigDetails?.data?.driverDetails?.endTime || "-"}</div>
                        </div>
                      </>
                       )} 

                  </div>
                </div>
              </div>
            </div>
         
            <div className='container mb-3'>
              {gigDetails?.data?.status === "completed" ||
                gigDetails?.data?.status === "delivered" ?
                (
                  gigDetails?.data?.rating !== "0" ? (


                    <div className='row text-center '>
                      <div className='col-12 mb-2 '>
                        <div className='w-100'>
                          <button 
                            className=" btn-primary 
                    d-flex justify-content-center text-decoration-none w-100 py-2"
                            data-bs-toggle="modal"
                            data-bs-target="#rated"
                            onClick={() => { setRatingState(gigDetails?.data?.rating) }}
                            style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          >
                             
                            <Rating
                              initialValue={Number(ratingState)}
                              allowFraction={true}
                            />
                          </button>
                        </div>
                      </div>
                      
                    </div>
                  ) : (
                    <div className='row text-center '>
                      <div className='col-12 mb-2 '>
                        <div className='w-100'>
                          <button 
                            className="button  btn-primary d-flex justify-content-center text-decoration-none w-100 py-3
                    "
                            onClick={() => {
                              navigate(`/opinion/${params?.gigId}`);
                            }}
                          >
                            Review rating
                          </button>
                        </div>
                      </div> 
                    </div>
                  )
                ) : ""}
            </div>


            {
                gigDetails?.data?.status === "cancelled" || gigDetails?.data?.status === "delivered" || gigDetails?.data?.status === "completed" || gigDetails?.data?.status === "expired"
                ?
                ("")
                :
                ( gigDetails?.data?.isPaymentDone ?
                 
                    <div className="container my-3">
                      <button
                        className="button btn-primary w-100 py-3 mb-3"
                        onClick={() => {
                          navigateToMap();
                        }}
                      >
                        Track your order
                      </button> 
                    </div>
                    : <div className="container my-3">
                      <button
                        className="button btn-primary bg-danger w-100 py-3 mb-3"
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        Pending/fail
                      </button> 
                    </div>
                )
            }
          </>
        )}
        <div className="d-none" >
          <NewMapTwo />
        </div>
      </main>

      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="rated"
          tabIndex={-1}
          aria-labelledby="ratedLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <div className='h4 fw-semibold'>
                  Already rated
                </div>
                <div className="px-2 text-center h1 d-flex justify-content-center" style={{
                  pointerEvents: "none",
                  cursor: "not-allowed"
                }}>
                   <Rating
                    initialValue={Number(ratingState)}
                    allowFraction={true}
                  /> 
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => navigate("/")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="rated"
        tabindex="-1"
        aria-labelledby="ratedLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3 position-relative">
            <div className="logout-icon h1">
              <i className="bi bi-box-arrow-left fs-3 fw-bold "></i>
            </div>

            <div className="text-center mt-4">
              <h1 className="modal-title fs-5 fw-bold" id="ratedLabel">
                Sign Out?
              </h1>
            </div>
            <div className="modal-body py-3 ps-0 text-center fw-semibold">
              Are you sure you want to Sign out?
            </div>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="rounded-2 text-white  py-3 px-5 border-0 light-color"
                data-bs-dismiss="modal"
              >
                SIGN OUT
              </button>
              <button
                type="button"
                className="rounded-2  text-fade py-3 px-5 border-0 ms-1"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Edit;
